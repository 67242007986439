import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../axios";
import Loader from "../loader/Loader";

const EditPartners = (props) => {
  const [partnersdata, editpartnersdata] = useState([]);
  const [languages, addlanguages] = useState("al");
  const [editordata, seteditordata] = useState("");
  const [isloading, setisloading] = useState(false);
  const [userroles, adduserroles] = useState([]);
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");
  const [image, setImage] = useState(null);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const getCurrentPartners = () => {
    axios
      .get("partners/id?id=" + props.match.params.id)
      .then((data) => {
        editpartnersdata(data?.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };
  useEffect(() => {
    getCurrentPartners();
  }, []);

  const name_al = (e) => {
    const ud = Object.assign({}, partnersdata);
    ud.name_al = e.target.value;
    editpartnersdata(ud);
  };
  const name_sr = (e) => {
    const ud = Object.assign({}, partnersdata);
    ud.name_sr = e.target.value;
    editpartnersdata(ud);
  };
  const name_en = (e) => {
    const ud = Object.assign({}, partnersdata);
    ud.name_en = e.target.value;
    editpartnersdata(ud);
  };

  const desc_al = (value) => {
    setContent(value);
  };
  const desc_sr = (value) => {
    setContentsr(value);
  };
  const desc_en = (value) => {
    setContenten(value);
  };

  const language = (lang) => {
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    partnersdata.desc_al = content;
    partnersdata.desc_en = contenten;
    partnersdata.desc_sr = contentsr;

    axios
      .put("partners", partnersdata)
      .then((res) => {
        console.log(res?.data);
        toast.success("Partner has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  const imageDataChange = (e) => {
    const ud = Object.assign({}, image);
    ud.pic_url = e.target.files[0];
    setImage(ud);
  };

  const saveImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image.pic_url);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("partners/image/" + props.match.params.id, formData, config)
      .then((res) => {
        console.log(res.data);
        toast.success("Image has been changed successfully!");
        getCurrentPartners();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="partners p-5 text-left">
      <div className="page-title">
        <h1>Edit Partner</h1>
      </div>
      {!isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row p-3">
            <img
              src={`${process.env.REACT_APP_UPLOADS + partnersdata.pic_url}`}
              alt="Country Image"
              width="150px"
            />
          </div>
          <input type="file" onChange={imageDataChange} />
          {!!image && <button onClick={saveImage}>Save Image</button>}

          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <input
                placeholder="Titulli"
                onChange={name_al}
                defaultValue={partnersdata.name_al}
              />
              <ReactQuill
                theme="snow"
                defaultValue={partnersdata.desc_al}
                onChange={desc_al}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages == "sr" && (
            <>
              <input
                placeholder="Naslov"
                onChange={name_sr}
                defaultValue={partnersdata.name_sr}
              />
              <ReactQuill
                theme="snow"
                defaultValue={partnersdata.desc_sr}
                onChange={desc_sr}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages == "en" && (
            <>
              <input
                placeholder="Title"
                onChange={name_en}
                value={partnersdata.name_en}
              />
              <ReactQuill
                theme="snow"
                defaultValue={partnersdata.desc_en}
                onChange={desc_en}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          <button type="submit">Edit Partner</button>
        </form>
      )}
    </div>
  );
};

export default EditPartners;
