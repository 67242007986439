import React, { useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader/Loader";
import "./about_us.scss";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const AddAboutUs = () => {
  const [aboutus, addaboutus] = useState({});
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(true);
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");

  const content_al = (value) => {
    setContent(value);
  };
  const content_sr = (value) => {
    setContentsr(value);
  };
  const content_en = (value) => {
    setContenten(value);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    aboutus.content_al = content;
    aboutus.content_en = contenten;
    aboutus.content_sr = contentsr;

    let id;

    console.log("formdatea", aboutus);

    await axios
      .post("aboutus", aboutus)
      .then((data) => {
        console.log(data?.data);
        // aboutus(data?.data);
        // id = data.data.id;
        toast.success("Data has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="about_us p-5 text-left">
      <div className="page-title">
        <h1>Add About Us</h1>
      </div>
      {!isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          {/* <input type="file" />
          <input type="file" />
          <input type="file" /> */}

          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <ReactQuill
                theme="snow"
                value={content}
                onChange={content_al}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages == "sr" && (
            <>
              <ReactQuill
                theme="snow"
                value={contentsr}
                onChange={content_sr}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages == "en" && (
            <>
              <ReactQuill
                theme="snow"
                value={contenten}
                onChange={content_en}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          <button type="submit">Add</button>
        </form>
      )}
    </div>
  );
};

export default AddAboutUs;
