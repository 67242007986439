import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const DeleteMunicipalities = (props) => {
  const [munidata, addmunidata] = useState({});
  const [languages, addlanguages] = useState({});
  const [labels, addlabels] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    axios
      .get("municipalities/id?id=" + props.match.params.id)
      .then((data) => {
        addmunidata(data.data);
        console.log("user", data.data);
        setisloading(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("municipalities/?id=" + munidata.id)
      .then((data) => {
        toast.success(
          `Municipality ${munidata.name_sr} was deleted successfully`
        );
        console.log(data);
        window.history.back();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
  console.log(languages);
  console.log(labels);

  return (
    <div className="municipalities p-5 text-left">
      <div className="page-title">
        <h1>Delete Municipality</h1>
      </div>
      <div className="row mb-3 text">
        By clicking delete button you agree to delete municipality with the
        following data:
      </div>
      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Albanian</label>
          <input type="text" defaultValue={munidata.name_al} disabled />
          <label>Serbian</label>
          <input type="text" defaultValue={munidata.name_sr} disabled />
          <label>English</label>
          <input type="text" value={munidata.name_en} disabled />

          <button type="submit">Delete Municipality</button>
        </form>
      )}
    </div>
  );
};
export default DeleteMunicipalities;
