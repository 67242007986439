import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import Search from "../search/Search";
import ReactPaginate from "react-paginate";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";
import "./reported_cases.scss";

const ReportedCases = (props) => {
  const [reportedCases, setReportedCases] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 5;
  const productsPerList = 50;
  const pagesVisited = pageNumber * productsPerPage;
  let displayCases = [];
  const pageCount = Math.ceil(filtered.length / productsPerPage);

  const changePage = ({ selected }) => {
    console.log(selected);
    setPageNumber(selected);
  };

  useEffect(() => {
    axios
      .get("report-cases/all")
      .then((res) => {
        setReportedCases(res?.data);
        setFiltered(res?.data);
        setIsLoading(false);
        console.log(reportedCases);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  return (
    <div className="reported_cases p-5 text-left">
      <div className="page-title">
        <h1> Reported Cases</h1>
      </div>
      {/* <div className="row ml-n4 search">
        <div className="col-md-9">
          <Search
            data={reportedCases}
            filtered={setFiltered}
            setPageNumber={setPageNumber}
          />
        </div>

        <div className="col-md-3">
          <Link to="/casediscussions" className="myButton">
            Case Discussionss
          </Link>
        </div>
      </div> */}
      {/* <div className="row">
        <Link to="/users/add" className="myButton">
          Add New User
        </Link>
      </div> */}
      <div className="listimi-tabela">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="listimi-head">
              <div className="row ">
                <div className="col-1">ID</div>
                <div className="col-1">Case</div>
                <div className="col-2">Report Type</div>
                <div className="col-2">Municipality</div>
                <div className="col-2">Agency</div>
                {/* <div className='col-1'>{filtered.def_lang} </div> */}
                <div className="col-1">Contact</div>
                <div className="col-1">Email</div>
                {/* <div className="col-1 "> </div>
                <div className="col-1 "> </div> */}
                <div className="col-2 status"></div>
              </div>
            </div>{" "}
            {
              (displayCases = filtered
                ?.slice(pagesVisited, pagesVisited + productsPerPage)
                ?.map((filtered) => {
                  return (
                    <div className="listimi showme" key={filtered.id}>
                      {/* <Link to={"/casediscussions/" + filtered.id}> */}
                      <div className="row ">
                        <div className="col-1 text-overflow">
                          {filtered.id}{" "}
                        </div>
                        <div className="col-1 text-overflow">
                          {filtered.case_al}{" "}
                        </div>
                        <div className="col-2 text-overflow">
                          {filtered.cat_en}{" "}
                        </div>
                        <div className="col-2 text-overflow">
                          {filtered.municipality_al}
                        </div>
                        {/* <div className='col-1'>{filtered.def_lang} </div> */}
                        <div className="col-2 text-overflow">
                          {filtered.gov_al}
                        </div>
                        <div className="col-1 text-overflow">
                          {filtered.phone}
                        </div>
                        <div className="col-1 text-overflow">
                          {filtered.email}
                        </div>

                        <div className="col-1  delete ">
                          <Link
                            className={"fa fa-trash"}
                            to={"/editreportedcases/delete/" + filtered.id}
                          ></Link>
                        </div>
                        <div className="col-1 edit">
                          <Link
                            className={"fa fa-comments"}
                            to={"/report-cases/" + filtered.id}
                          ></Link>
                        </div>
                      </div>
                      {/* </Link> */}
                    </div>
                  );
                }))
            }
          </>
        )}
      </div>
      <div className="row mt-5">
        <div className="col-12">
          {reportedCases.length >= productsPerPage ? (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportedCases;
