import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import "./gov_agencies.scss";

const EditGovAgencies = (props) => {
  const [govagencies, addgovagencies] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    axios
      .get("gov-agencies/id?id=" + props.match.params.id)
      .then((data) => {
        addgovagencies(data.data);
        console.log("govagencies", data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const type = (e) => {
    const ud = Object.assign({}, govagencies);
    ud.type = e.target.value;
    addgovagencies(ud);
  };

  const name_al = (e) => {
    const ud = Object.assign({}, govagencies);
    ud.name_al = e.target.value;
    addgovagencies(ud);
  };

  const name_sr = (e) => {
    const ud = Object.assign({}, govagencies);
    ud.name_sr = e.target.value;
    addgovagencies(ud);
  };

  const name_en = (e) => {
    const ud = Object.assign({}, govagencies);
    ud.name_en = e.target.value;
    addgovagencies(ud);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("gov-agencies", govagencies)
      .then((data) => {
        toast.success(
          `Agency ${govagencies.name_en} has been edited successfully!`
        );
        console.log(data);
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="govagencies p-5 text-left">
      <div className="page-title">
        <h1>Edit Government Agencies</h1>
      </div>

      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Type</label>
          <select
            type="text"
            placeholder="name_al"
            onChange={type}
            defaultValue={govagencies.type}
          >
            <option value={null}>Select Type</option>
            <option value={"National"}>National</option>
            <option value={"Local"}>Local</option>
          </select>

          <label>Albanian</label>
          <input
            type="text"
            placeholder="name_al"
            onChange={name_al}
            defaultValue={govagencies.name_al}
          ></input>

          <label>Serbian</label>
          <input
            type="text"
            placeholder="name_sr"
            onChange={name_sr}
            defaultValue={govagencies.name_sr}
          ></input>

          <label>English</label>
          <input
            type="text"
            placeholder="name_en"
            onChange={name_en}
            defaultValue={govagencies.name_en}
          ></input>

          <button type="submit">Edit Gov. Agencies</button>
        </form>
      )}
    </div>
  );
};
export default EditGovAgencies;
