import React, { useState } from "react";
import CaseDiscussion from "../CaseDiscussion/case_discussion";
import ReportCases from "../reported_cases/reported_cases";
import "./show_case.scss";

const ShowCases = () => {
  const [isReport, setisReport] = useState(true);
  const [isDiscussion, setisDiscussion] = useState(false);
  const showReports = (e) => {
    setisDiscussion(false);
    setisReport(true);
  };
  const showDiscussions = (e) => {
    setisReport(false);
    setisDiscussion(true);
  };
  return (
    <div className="show-case text-left">
      <div className={"row  grid"}>
        <i
          className={
            isReport
              ? "modal-button active-modal far fa-th-large "
              : "modal-button fas fa-th-large "
          }
          onClick={showReports}
        ></i>

        <i
          className={
            isDiscussion
              ? "modal-button active-modal fas  far fa-list-alt"
              : "modal-button far  fa-list-alt"
          }
          onClick={showDiscussions}
        ></i>
      </div>
      <div className="row w-100 mt-4 cases">
        {/* show reports */}
        {isReport && (
          <div className="reports-dashboard">
            <CaseDiscussion />
          </div>
        )}
        {isDiscussion && <ReportCases />}
      </div>
    </div>
  );
};

export default ShowCases;
