import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../axios";
import Loader from "../loader/Loader";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const EditNews = (props) => {
  const [newsdata, editnewsdata] = useState([]);
  const [languages, editlanguages] = useState("al");
  const [isloading, setisloading] = useState(false);
  const [userroles, adduserroles] = useState([]);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");
  const [user, setUser] = useState();

  const getCurrentNews = () => {
    axios
      .get("news/id?id=" + props.match.params.id)
      .then((data) => {
        editnewsdata(data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });

    axios
      .get("users/" + window.localStorage.getItem("id"))
      .then((data) => {
        setUser(data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });

    axios
      .get("users/all")
      .then((data) => {
        adduserroles(data?.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  useEffect(() => {
    getCurrentNews();
  }, []);

  const news_date = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.news_date = e.target.value;
    editnewsdata(ud);
  };

  const name_al = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.name_al = e.target.value;
    editnewsdata(ud);
  };

  const name_sr = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.name_sr = e.target.value;
    editnewsdata(ud);
  };

  const name_en = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.name_en = e.target.value;
    editnewsdata(ud);
  };

  const desc_al = (value) => {
    setContent(value);
  };

  const desc_sr = (value) => {
    setContentsr(value);
  };

  const desc_en = (value) => {
    setContenten(value);
  };

  const createdBy = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.created_by = e.target.value;
    editnewsdata(ud);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    newsdata.desc_al = content;
    newsdata.desc_sr = contentsr;
    newsdata.desc_en = contenten;

    axios
      .put("news", newsdata)
      .then((res) => {
        console.log(res?.data);
        toast.success("News has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  const imageDataChange = (e) => {
    const ud = Object.assign({}, image);
    ud.pic_url = e.target.files[0];
    setImage(ud);
  };

  const saveImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image.pic_url);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("news/image/" + props.match.params.id, formData, config)
      .then((res) => {
        console.log(res.data);
        toast.success("Image has been changed successfully!");
        getCurrentNews();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="news p-5 text-left">
      <div className="page-title">
        <h1>Edit News</h1>
      </div>

      {!isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row p-3">
            <img
              src={`${process.env.REACT_APP_UPLOADS + newsdata.pic_url}`}
              alt="Country Image"
              width="300px"
            />
          </div>

          <input type="file" onChange={imageDataChange} />
          {!!image && <button onClick={saveImage}>Save Image</button>}
          <input
            type="date"
            onChange={news_date}
            defaultValue={newsdata?.news_date?.slice(0, 10)}
          />

          <select onChange={createdBy} defaultValue={newsdata.created_by}>
            <option value={user?.id}>{user?.full_name}</option>
            {/* {userroles?.map((el) => {
              return (
                <option value={el?.id} key={el?.id} selected>
                  {el?.full_name}
                </option>
              );
            })} */}
          </select>

          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <input
                placeholder="Titulli"
                onChange={name_al}
                defaultValue={newsdata.name_al}
              />
              <ReactQuill
                theme="snow"
                defaultValue={newsdata.desc_al}
                onChange={desc_al}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          {languages == "sr" && (
            <>
              <input
                placeholder="Naslov"
                onChange={name_sr}
                defaultValue={newsdata.name_sr}
              />
              <ReactQuill
                theme="snow"
                defaultValue={newsdata.desc_sr}
                onChange={desc_sr}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          {languages == "en" && (
            <>
              <input
                placeholder="Title"
                onChange={name_en}
                defaultValue={newsdata.name_en}
              />
              <ReactQuill
                theme="snow"
                defaultValue={newsdata.desc_en}
                onChange={desc_en}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          <button type="submit">Edit News</button>
        </form>
      )}
    </div>
  );
};

export default EditNews;
