import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader/Loader";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const EditCategories = (props) => {
  const [reportcategories, editreportcategories] = useState({});
  const [userroles, adduserroles] = useState([]);
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(true);
  const [editordata, seteditordata] = useState("");
  const [content, setContent] = useState("");
  const [contenten, setContenten] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [image, setImage] = useState(null);

  const getCurrentCategory = () => {
    axios
      .get("report-categories/id?id=" + props.match.params.id)
      .then((data) => {
        editreportcategories(data?.data);
        // console.log("userdata", data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  useEffect(() => {
    getCurrentCategory();
  }, []);

  const name_al = (e) => {
    const ud = Object.assign({}, reportcategories);
    ud.name_al = e.target.value;
    editreportcategories(ud);
    // console.log('seeme',reportcategories)
  };
  const name_sr = (e) => {
    const ud = Object.assign({}, reportcategories);
    ud.name_sr = e.target.value;
    editreportcategories(ud);
    // console.log('seeme',reportcategories)
  };
  const name_en = (e) => {
    const ud = Object.assign({}, reportcategories);
    ud.name_en = e.target.value;
    editreportcategories(ud);
    // console.log('seeme',reportcategories)
  };
  const desc_al = (value) => {
    setContent(value);
    console.log(content);
  };
  const desc_en = (value) => {
    setContenten(value);
    console.log(content);
  };
  const desc_sr = (value) => {
    setContentsr(value);
    console.log(content);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    reportcategories.desc_al = content;
    reportcategories.desc_en = contenten;
    reportcategories.desc_sr = contentsr;

    axios
      .put("report-categories", reportcategories)
      .then((res) => {
        console.log(res?.data);
        toast.success("News has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  console.log("sfs", reportcategories);
  return (
    <>
      <div className="report_categories p-5 text-left">
        <div className="page-title">
          <h1>Edit Report Categories</h1>
        </div>
        {!isloading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="row ml-2 p-1 mb-5">
              <a
                className={languages == "al" ? "lang-btn-active" : "lang-btn"}
                onClick={() => language("al")}
              >
                Albanian
              </a>
              <a
                className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
                onClick={() => language("sr")}
              >
                Serbian
              </a>
              <a
                className={languages == "en" ? "lang-btn-active" : "lang-btn"}
                onClick={() => language("en")}
              >
                English
              </a>
            </div>

            {languages == "al" && (
              <>
                <input
                  placeholder="Titulli"
                  onChange={name_al}
                  defaultValue={reportcategories.name_al}
                />
                {/* <textarea placeholder="Përmbajtja" onChange={desc_al} /> */}
                <ReactQuill
                  theme="snow"
                  // value={reportcategories.desc_al}
                  // onChange={testeditor}
                  defaultValue={reportcategories.desc_al}
                  placeholder={"Write something awesome..."}
                  onChange={desc_al}
                  formats={formats}
                  modules={modules}
                />
              </>
            )}
            {languages == "sr" && (
              <>
                <input
                  placeholder="Naslov"
                  onChange={name_sr}
                  defaultValue={reportcategories.name_sr}
                />
                {/* <textarea placeholder="Sadrzaj" onChange={desc_sr} /> */}
                <ReactQuill
                  theme="snow"
                  // value={reportcategories.desc_al}
                  // onChange={testeditor}
                  defaultValue={reportcategories.desc_sr}
                  placeholder={"Write something awesome..."}
                  onChange={desc_sr}
                  formats={formats}
                  modules={modules}
                />
              </>
            )}
            {languages == "en" && (
              <>
                <input
                  placeholder="Title"
                  onChange={name_en}
                  defaultValue={reportcategories.name_en}
                />
                {/* <textarea placeholder="Content" onChange={desc_en} /> */}
                <ReactQuill
                  theme="snow"
                  // value={reportcategories.desc_al}
                  // onChange={testeditor}
                  defaultValue={reportcategories.desc_en}
                  placeholder={"Write something awesome..."}
                  onChange={desc_en}
                  formats={formats}
                  modules={modules}
                />
              </>
            )}

            <button type="submit">Edit Report Categorie</button>
          </form>
        )}
      </div>
    </>
  );
};

export default EditCategories;
