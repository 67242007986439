import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";

const EditMunicipalities = (props) => {
  const [munidata, addmunidata] = useState({});
  const [userroles, adduserroles] = useState({});
  const [languages, addlanguages] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    // defValues();

    axios
      .get("municipalities/id?id=" + props.match.params.id)
      .then((data) => {
        addmunidata(data.data);
        console.log("munidata", data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  // const defValues = () => {
  //     const ud = Object.assign({}, munidata);
  //     ud.auth = 'initial-auth';
  //     ud.mobileauth = 'initial-auth';
  //     addmunidata(ud);
  // }

  const name_al = (e) => {
    const ud = Object.assign({}, munidata);
    ud.name_al = e.target.value;
    addmunidata(ud);
    // console.log('seeme',munidata)
  };
  const name_sr = (e) => {
    // console.log(e)
    const ud = Object.assign({}, munidata);
    ud.name_sr = e.target.value;
    addmunidata(ud);
    // console.log('seeme',munidata)
  };
  const name_en = (e) => {
    // console.log(e)
    const ud = Object.assign({}, munidata);
    ud.name_en = e.target.value;
    addmunidata(ud);
    // console.log('seeme',munidata)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("municipalities", munidata)
      .then((data) => {
        toast.success(
          `Municipality ${munidata.name_en} has been edited successfully!`
        );
        console.log(data);
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="municipalities p-5 text-left">
      <div className="page-title">
        <h1>Edit Municipality</h1>
      </div>
      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Albanian</label>
          <input
            type="text"
            placeholder="name_al"
            onChange={name_al}
            defaultValue={munidata.name_al}
          ></input>
          <label>Serbian</label>
          <input
            type="text"
            placeholder="name_sr"
            onChange={name_sr}
            defaultValue={munidata.name_sr}
          ></input>
          <label>English</label>
          <input
            type="text"
            placeholder="name_en"
            onChange={name_en}
            defaultValue={munidata.name_en}
          ></input>
          <button type="submit">Edit Municipality</button>
        </form>
      )}
    </div>
  );
};
export default EditMunicipalities;
