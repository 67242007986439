import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader/Loader";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const AddNews = (props) => {
  const [newsdata, addnewsdata] = useState({});
  const [userroles, adduserroles] = useState([]);
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(true);
  const [editordata, seteditordata] = useState("");
  const [content, setContent] = useState("");
  const [contenten, setContenten] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [image, setImage] = useState(null);
  const [user, setUser] = useState();

  useEffect(() => {
    axios
      .get("users/" + window.localStorage.getItem("id"))
      .then((data) => {
        setUser(data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });

    axios
      .get("users/all")
      .then((data) => {
        adduserroles(data?.data);
        setisloading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const news_date = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.news_date = e.target.value;
    addnewsdata(ud);
  };

  const name_al = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.name_al = e.target.value;
    addnewsdata(ud);
  };

  const name_sr = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.name_sr = e.target.value;
    addnewsdata(ud);
  };

  const name_en = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.name_en = e.target.value;
    addnewsdata(ud);
  };

  const desc_al = (value) => {
    setContent(value);
    console.log(content);
  };

  const desc_en = (value) => {
    setContenten(value);
    console.log(content);
  };

  const desc_sr = (value) => {
    setContentsr(value);
    console.log(content);
  };

  const created_by = (e) => {
    const ud = Object.assign({}, newsdata);
    ud.created_by = e.target.value;
    addnewsdata(ud);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const pic_url = (e) => {
    let img = e.target.files[0];
    setImage(img);
    console.log("fajllimrena", image);
  };

  console.log("image incoming", image);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", image);

    // const variables = {
    //   desc_al: content,
    // };
    newsdata.desc_al = content;
    newsdata.desc_en = contenten;
    newsdata.desc_sr = contentsr;

    let id;

    console.log("formdatea", newsdata);

    await axios
      .post("news", newsdata)
      .then((data) => {
        id = data?.data?.id;
        // toast.success("News has been added successfully!");
        // window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });

    await axios
      .post(`news/image/${id}`, formData)
      .then((data) => {
        console.log(data);
        toast.success("Events has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  console.log("editordataaa", editordata);

  return (
    <div className="news p-5 text-left">
      <div className="page-title">
        <h1>Add News</h1>
      </div>
      {isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <input type="file" onChange={pic_url} />
          <input
            type="date"
            onChange={news_date}
            required
            title="This field is required"
            placeholder="From Date"
          />

          <select required title="This field is required" onChange={created_by}>
            <option value="" disabled selected>
              Created By
            </option>
            <option value={user?.id}>{user?.full_name}</option>
            {/* {userroles?.map((el, index) => {
              return (
                <option value={el.id} key={index}>
                  {el?.full_name}
                </option>
              );
            })} */}
          </select>

          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <input
                placeholder="Titulli"
                onChange={name_al}
                defaultValue={newsdata.name_al}
              />
              {/* <textarea placeholder="Përmbajtja" onChange={desc_al} /> */}
              <ReactQuill
                theme="snow"
                // value={newsdata.desc_al}
                // onChange={testeditor}
                value={content}
                placeholder={"Write something awesome..."}
                onChange={desc_al}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages == "sr" && (
            <>
              <input
                placeholder="Naslov"
                onChange={name_sr}
                defaultValue={newsdata.name_sr}
              />
              {/* <textarea placeholder="Sadrzaj" onChange={desc_sr} /> */}
              <ReactQuill
                theme="snow"
                // value={newsdata.desc_al}
                // onChange={testeditor}
                value={contentsr}
                placeholder={"Write something awesome..."}
                onChange={desc_sr}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages == "en" && (
            <>
              <input
                placeholder="Title"
                onChange={name_en}
                defaultValue={newsdata.name_en}
              />
              {/* <textarea placeholder="Content" onChange={desc_en} /> */}
              <ReactQuill
                theme="snow"
                // value={newsdata.desc_al}
                // onChange={testeditor}
                value={contenten}
                placeholder={"Write something awesome..."}
                onChange={desc_en}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          <button type="submit">Add News</button>
        </form>
      )}
    </div>
  );
};
export default AddNews;
