import React, { useEffect, useState } from "react";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";
import axios from "../../axios";

const EditUser = (props) => {
  const [users, setusers] = useState([]);
  const [userdata, adduserdata] = useState({});
  const [isloading, setisloading] = useState(false);
  // const [image, setImage] = useState(null);

  const getCurrentUsers = () => {
    axios
      .get("users/all")
      .then((res) => {
        setusers(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("users/" + props.match.params.id)
      .then((data) => {
        adduserdata(data?.data);
        // console.log("userdata", data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  useEffect(() => {
    getCurrentUsers();
  }, []);

  const full_name = (e) => {
    const ud = Object.assign({}, userdata);
    ud.full_name = e.target.value;
    adduserdata(ud);
  };
  const email = (e) => {
    const ud = Object.assign({}, userdata);
    ud.email = e.target.value;
    adduserdata(ud);
  };
  const pass = (e) => {
    const ud = Object.assign({}, userdata);
    ud.pass = e.target.value;
    adduserdata(ud);
  };
  const role = (e) => {
    const ud = Object.assign({}, userdata);
    ud.role = e.target.value;
    adduserdata(ud);
  };
  const active = (e) => {
    const ud = Object.assign({}, userdata);
    ud.active = e.target.value;
    adduserdata(ud);
  };
  // const imageDataChange = (e) => {
  //   const ud = Object.assign({}, image);
  //   ud.pic_url = e.target.files[0];
  //   setImage(ud);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .put("users", userdata)
      .then((data) => {
        toast.success(
          `User ${userdata.full_name} has been edited successfully!`
        );
        // console.log(data);
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };
  // const saveImage = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("image", image.pic_url);
  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   };

  //   axios
  //     .post("users/image/" + props.match.params.id, formData, config)
  //     .then((res) => {
  //       console.log(res.data);
  //       toast.success("Image has been changed successfully!");
  //       getCurrentUsers();
  //     })
  //     .catch((err) => {
  //       toast.error("Something went wrong!");
  //       console.log(err);
  //     });
  // };

  return (
    <div className="users p-5 text-left">
      <div className="page-title">
        <h1>Edit User</h1>
      </div>

      {!isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          {/* <img
            src={`${process.env.REACT_APP_UPLOADS + users.pic_url}`}
            alt="Country Image"
            width="300px"
          />

          <input type="file" onChange={imageDataChange} />
          {!!image && <button onClick={saveImage}>Save Image</button>} */}
          <input
            type="text"
            placeholder="full name"
            onChange={full_name}
            defaultValue={userdata?.full_name}
          ></input>

          <input
            type="email"
            placeholder="email"
            onChange={email}
            defaultValue={userdata.email}
          ></input>

          <input
            type="password"
            placeholder="your password"
            onChange={pass}
            defaultValue={userdata.pass}
          ></input>

          <select
            placeholder="date active"
            onChange={active}
            defaultValue={userdata.active}
          >
            <option value={true}>Active</option>
            <option value={false}>Passive</option>
          </select>

          <select onChange={role} value={userdata.role}>
            {/* <option >Select</option> */}
            <option value={1}>Admin</option>
            <option value={2}>Client</option>
          </select>

          <button type="submit">Edit User</button>
        </form>
      )}
    </div>
  );
};
export default EditUser;
