import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import axios from "./axios";
import Auth from "./Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Login from "./components/Login/login";
import Header from "./components/Header/header";
import HeaderProfile from "./components/HeaderProfile/HeaderProfile";
import Dashboard from "./components/dashboard/dashboard";
import ReportedCases from "./components/reported_cases/reported_cases";
import CaseDiscussion from "./components/CaseDiscussion/case_discussion";
import Discussion from "./components/CaseDiscussion/discussion";
import Users from "./components/users/users";
import AddUser from "./components/users/add";
import EditUser from "./components/users/edit";
import DeleteUser from "./components/users/delete";
import News from "./components/news/news";
import AddNews from "./components/news/add";
import EditNews from "./components/news/edit";
import DeleteNews from "./components/news/delete";
import Events from "./components/events/events";
import AddEvents from "./components/events/add";
import EditEvents from "./components/events/edit";
import DeleteEvents from "./components/events/delete";
import Legislations from "./components/legislations/legislations";
import AddLegislations from "./components/legislations/add";
import EditLegislations from "./components/legislations/edit";
import DeleteLegislations from "./components/legislations/delete";
import Partners from "./components/partners/partners";
import AddPartners from "./components/partners/add";
import EditPartners from "./components/partners/edit";
import DeletePartners from "./components/partners/delete";
import AboutUs from "./components/about_us/about_us";
import AddAboutUs from "./components/about_us/add";
import EditAboutUs from "./components/about_us/edit";
import DeleteAboutUs from "./components/about_us/delete";
import Links from "./components/links/links";
import AddLinks from "./components/links/add";
import EditLinks from "./components/links/edit";
import DeleteLinks from "./components/links/delete";
import AdminSettings from "./components/admin_settings/admin_settings";
import AddAdminSettings from "./components/admin_settings/add";
import EditAdminSettings from "./components/admin_settings/edit";
import DeleteAdminSetting from "./components/admin_settings/delete";
import Municipalities from "./components/municipalities/municipalities";
import AddMunicipalities from "./components/municipalities/add";
import EditMunicipalities from "./components/municipalities/edit";
import DeleteMunicipalities from "./components/municipalities/delete";
import GovAgencies from "./components/gov_agencies/gov_agencies";
import AddGovAgencies from "./components/gov_agencies/add";
import EditGovAgencies from "./components/gov_agencies/edit";
import DeleteGovAgencies from "./components/gov_agencies/delete";
import CaseStatus from "./components/case_status/case_status";
import AddCaseStatus from "./components/case_status/add";
import EditCaseStatus from "./components/case_status/edit";
import DeleteCaseStatus from "./components/case_status/delete";
import LabelTranslations from "./components/labels_translations/labels_translations";
import AddLabelTranslations from "./components/labels_translations/add";
import EditLabelTranslations from "./components/labels_translations/edit";
import DeleteLabelTranslations from "./components/labels_translations/delete";
import ShowCases from "./components/ShowCases/show_case";
import MisionidheVizioni from "./components/misioni_vizoni/misioni_vizioni";
import AddMisioniVizioni from "./components/misioni_vizoni/add";
import EditMisioniVizioni from "./components/misioni_vizoni/edit";
import DeleteMisioniVizioni from "./components/misioni_vizoni/delete";
import ReportCategories from "./components/ReportCategories/report_categories";
import AddReportCategories from "./components/ReportCategories/add";
import DeleteReportCategory from "./components/ReportCategories/delete";
import EditCategories from "./components/ReportCategories/edit";
import Profile from "./components/Profile/profile";
import Community from "./components/Community/community";
import AddCommunity from "./components/Community/add";
import EditCommunity from "./components/Community/edit";
import DeleteCommunity from "./components/Community/delete";
import Mobile from "./components/Header/mobile";
import TermsCondition from "./components/terms_condition/terms_condition";
import AddTermsCondition from "./components/terms_condition/add";
import EditTermsConditions from "./components/terms_condition/edit";
import DeleteTermsConditions from "./components/terms_condition/delete";
import PrivacyPolicy from "./components/privacy_policy/privacy_policy";
import AddPrivacyPolicy from "./components/privacy_policy/add";
import EditPrivacyPolicy from "./components/privacy_policy/edit";
import DeletePrivacyPolicy from "./components/privacy_policy/delete";

function App() {
  const [data, setData] = useState([]);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  useEffect(() => {
    axios
      .get("users/all")
      .then((res) => {
        console.log(res?.data);
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        {Auth() ? (
          <>
            <div className="row">
              <div
                className={
                  !menuCollapse ? "col-md-3 col-sm-3 res_header3" : "col-md-1"
                }
              >
                <div className="ss">
                  <Header
                    menuCollapse={menuCollapse}
                    setMenuCollapse={setMenuCollapse}
                    menuIconClick={menuIconClick}
                  />
                </div>
                <div className="ss2"></div>
              </div>
              <div
                className={
                  !menuCollapse
                    ? "col-md-9 col-sm-9 app-header res_header2"
                    : "col-md-11 app-header res_header"
                }
              >
                <div className="ccc">
                  {/* <p>Mobile Menu</p> */}
                  <Mobile />
                </div>
                <HeaderProfile />

                <div
                  className={
                    !menuCollapse ? "mycontent" : "mycontent content_close"
                  }
                >
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route
                      exact
                      path="/reportedcases"
                      component={ReportedCases}
                    />
                    <Route
                      exact
                      path="/casediscussions"
                      component={CaseDiscussion}
                    />
                    <Route
                      exact
                      path="/report-cases/:id"
                      component={Discussion}
                    />
                    <Route exact path="/users" component={Users} />
                    <Route exact path="/users/add" component={AddUser} />
                    <Route exact path="/users/edit/:id" component={EditUser} />
                    <Route
                      exact
                      path="/users/delete/:id"
                      component={DeleteUser}
                    />
                    <Route exact path="/news" component={News} />
                    <Route exact path="/news/add" component={AddNews} />
                    <Route exact path="/news/edit/:id" component={EditNews} />
                    <Route
                      exact
                      path="/news/delete/:id"
                      component={DeleteNews}
                    />

                    <Route exact path="/events" component={Events} />
                    <Route exact path="/events/add" component={AddEvents} />
                    <Route
                      exact
                      path="/events/edit/:id"
                      component={EditEvents}
                    />
                    <Route
                      exact
                      path="/events/delete/:id"
                      component={DeleteEvents}
                    />

                    <Route
                      exact
                      path="/legislations"
                      component={Legislations}
                    />
                    <Route
                      exact
                      path="/legislations/add"
                      component={AddLegislations}
                    />
                    <Route
                      exact
                      path="/legislations/edit/:id"
                      component={EditLegislations}
                    />
                    <Route
                      exact
                      path="/legislations/delete/:id"
                      component={DeleteLegislations}
                    />

                    <Route exact path="/partners" component={Partners} />
                    <Route exact path="/partners/add" component={AddPartners} />
                    <Route
                      exact
                      path="/partners/edit/:id"
                      component={EditPartners}
                    />
                    <Route
                      exact
                      path="/partners/delete/:id"
                      component={DeletePartners}
                    />

                    <Route exact path="/aboutus" component={AboutUs} />
                    <Route exact path="/aboutus/add" component={AddAboutUs} />
                    <Route
                      exact
                      path="/aboutus/edit/:id"
                      component={EditAboutUs}
                    />
                    <Route
                      exact
                      path="/aboutus/delete/:id"
                      component={DeleteAboutUs}
                    />

                    <Route exact path="/links" component={Links} />
                    <Route exact path="/links/add" component={AddLinks} />
                    <Route exact path="/links/edit/:id" component={EditLinks} />
                    <Route
                      exact
                      path="/links/delete/:id"
                      component={DeleteLinks}
                    />

                    <Route
                      exact
                      path="/adminsettings"
                      component={AdminSettings}
                    />
                    <Route
                      exact
                      path="/adminsettings/add"
                      component={AddAdminSettings}
                    />
                    <Route
                      exact
                      path="/adminsettings/edit/:id"
                      component={EditAdminSettings}
                    />
                    <Route
                      exact
                      path="/adminsettings/delete/:id"
                      component={DeleteAdminSetting}
                    />

                    <Route
                      exact
                      path="/municipalities"
                      component={Municipalities}
                    />
                    <Route
                      exact
                      path="/municipalities/add"
                      component={AddMunicipalities}
                    />
                    <Route
                      exact
                      path="/municipalities/edit/:id"
                      component={EditMunicipalities}
                    />
                    <Route
                      exact
                      path="/municipalities/delete/:id"
                      component={DeleteMunicipalities}
                    />

                    <Route exact path="/govagencies" component={GovAgencies} />
                    <Route
                      exact
                      path="/govagencies/add"
                      component={AddGovAgencies}
                    />
                    <Route
                      exact
                      path="/govagencies/edit/:id"
                      component={EditGovAgencies}
                    />
                    <Route
                      exact
                      path="/govagencies/delete/:id"
                      component={DeleteGovAgencies}
                    />

                    <Route exact path="/casestatus" component={CaseStatus} />
                    <Route
                      exact
                      path="/casestatus/add"
                      component={AddCaseStatus}
                    />
                    <Route
                      exact
                      path="/casestatus/edit/:id"
                      component={EditCaseStatus}
                    />
                    <Route
                      exact
                      path="/casestatus/delete/:id"
                      component={DeleteCaseStatus}
                    />

                    <Route
                      exact
                      path="/labeltranslations"
                      component={LabelTranslations}
                    />
                    <Route
                      exact
                      path="/labeltranslations/add"
                      component={AddLabelTranslations}
                    />
                    <Route
                      exact
                      path="/labeltranslations/edit/:id"
                      component={EditLabelTranslations}
                    />
                    <Route
                      exact
                      path="/labeltranslations/delete/:id"
                      component={DeleteLabelTranslations}
                    />
                    <Route
                      exact
                      path="/misonidhevizioni"
                      component={MisionidheVizioni}
                    />
                    <Route
                      exact
                      path="/misionidhevizioni/add"
                      component={AddMisioniVizioni}
                    />
                    <Route
                      exact
                      path="/misionidhevizioni/edit/:id"
                      component={EditMisioniVizioni}
                    />
                    <Route
                      exact
                      path="/misionidhevizioni/delete/:id"
                      component={DeleteMisioniVizioni}
                    />
                    <Route
                      exact
                      path="/report-categories"
                      component={ReportCategories}
                    />
                    <Route
                      exact
                      path="/report-categories/add"
                      component={AddReportCategories}
                    />
                    <Route
                      exact
                      path="/report-categories/edit/:id"
                      component={EditCategories}
                    />
                    <Route
                      exact
                      path="/report-categories/delete/:id"
                      component={DeleteReportCategory}
                    />
                    <Route exact path="/community" component={Community} />
                    <Route
                      exact
                      path="/community/add"
                      component={AddCommunity}
                    />
                    <Route
                      exact
                      path="/community/edit/:id"
                      component={EditCommunity}
                    />
                    <Route
                      exact
                      path="/community/delete/:id"
                      component={DeleteCommunity}
                    />
                    <Route
                      exact
                      path="/terms_conditions"
                      component={TermsCondition}
                    />
                    <Route
                      exact
                      path="/terms_conditions/add"
                      component={AddTermsCondition}
                    />
                    <Route
                      exact
                      path="/terms_conditions/edit/:id"
                      component={EditTermsConditions}
                    />
                    <Route
                      exact
                      path="/terms_conditions/delete/:id"
                      component={DeleteTermsConditions}
                    />

                    <Route
                      exact
                      path="/privacy_policy"
                      component={PrivacyPolicy}
                    />
                    <Route
                      exact
                      path="/privacy_policy/add"
                      component={AddPrivacyPolicy}
                    />
                    <Route
                      exact
                      path="/privacy_policy/edit/:id"
                      component={EditPrivacyPolicy}
                    />
                    <Route
                      exact
                      path="/privacy_policy/delete/:id"
                      component={DeletePrivacyPolicy}
                    />
                    <Route exact path="/showcases" component={ShowCases} />
                    <Route exact path="/profile" component={Profile} />
                  </Switch>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Redirect exact to="/login" />
            <Route exact path="/login" component={Login} />
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
