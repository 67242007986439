import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../axios";

const DeleteLegislations = (props) => {
  const [legislationdata, deletelegislationdata] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [languages, editlanguages] = useState("al");

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  useEffect(() => {
    axios
      .get("users/all")
      .then((data) => setUsersData(data?.data))
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
    axios
      .get("legislations/id?id=" + props.match.params.id)
      .then((data) => {
        deletelegislationdata(data?.data);
        console.log("userdata", data?.data);
        // setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("legislations/?id=" + legislationdata.id)
      .then((data) => {
        console.log(data?.data);
        window.history.back();
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="legislation p-5 text-left">
      <div className="page-title">
        <h1>Delete Legislations</h1>
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={handleSubmit}>
        <input
          defaultValue={legislationdata?.leg_date?.slice(0, 10)}
          disabled
        />

        <input
          defaultValue={legislationdata?.created_at?.slice(0, 10)}
          disabled
        />

        <select defaultValue={legislationdata.created_by} disabled>
          {usersData?.map((el, index) => {
            return (
              <option value={el?.id} key={index}>
                {el?.full_name}
              </option>
            );
          })}
        </select>

        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input
              placeholder="Titulli"
              defaultValue={legislationdata.name_al}
              disabled
            />
            <textarea
              placeholder="Përmbajtja"
              defaultValue={legislationdata.desc_al}
              disabled
            />
            {/* <ReactQuill
            theme="snow"
            value={editordata}
            onChange={seteditordata}
            formats={formats}
            modules={modules}
          /> */}
          </>
        )}
        {languages == "sr" && (
          <>
            <input
              placeholder="Naslov"
              defaultValue={legislationdata.name_sr}
              disabled
            />
            <textarea
              placeholder="Sadrzaj"
              defaultValue={legislationdata.desc_sr}
              disabled
            />
          </>
        )}
        {languages == "en" && (
          <>
            <input
              placeholder="Title"
              value={legislationdata.name_en}
              disabled
            />
            <textarea
              placeholder="Content"
              value={legislationdata.desc_en}
              disabled
            />
          </>
        )}
        <button type="submit">Delete Legislation</button>
      </form>
      {/* } */}
    </div>
  );
};

export default DeleteLegislations;
