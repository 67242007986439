import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader/Loader";
import "./privacy_policy.scss";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const EditPrivacyPolicy = (props) => {
  const [privacypolicy, editprivacypolicy] = useState({});
  const [languages, editlanguages] = useState("al");
  const [isloading, setisloading] = useState(false);
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");

  useEffect(() => {
    axios
      .get("privacy_policy/" + props.match.params.id)
      .then((data) => {
        editprivacypolicy(data.data);
        console.log("userdata", data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const content_al = (value) => {
    setContent(value);
  };
  const content_sr = (value) => {
    setContentsr(value);
  };
  const content_en = (value) => {
    setContenten(value);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    privacypolicy.content_al = content;
    privacypolicy.content_sr = contentsr;
    privacypolicy.content_en = contenten;

    axios
      .put("privacy_policy", privacypolicy)
      .then((res) => {
        console.log(res?.data);
        toast.success("News has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };
  return (
    <div className="news p-5 text-left">
      <div className="page-title">
        <h1>Edit Privacy Policy</h1>
      </div>
      {!isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <ReactQuill
                theme="snow"
                defaultValue={privacypolicy?.content_al}
                onChange={content_al}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages == "sr" && (
            <>
              <ReactQuill
                theme="snow"
                defaultValue={privacypolicy?.content_sr}
                onChange={content_sr}
                formats={formats}
                modules={modules}
              />
            </>
          )}
          {languages == "en" && (
            <>
              <ReactQuill
                theme="snow"
                defaultValue={privacypolicy?.content_en}
                onChange={content_en}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          <button type="submit">Edit</button>
        </form>
      )}
    </div>
  );
};

export default EditPrivacyPolicy;
