import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { SendOutlined } from "@ant-design/icons";
import axios from "../../axios";
import "./case_discussion.scss";

const Discussion = () => {
  const [discussion, setdiscussion] = useState([]);
  const [comments, setcomments] = useState([]);
  const [postcomment, setpostcomment] = useState("");
  const [user, setUser] = useState();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get("report-cases/" + id)
      .then((res) => {
        setdiscussion(res?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`case-discussion/case_id/${id}`)
      .then((res) => {
        setcomments(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [postcomment]);

  const content_al = (e) => {
    setpostcomment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let dataa = {
      uuid: window.localStorage.getItem("id"),
      case_id: id,
      content_al: postcomment,
    };

    axios
      .post("case-discussion", dataa)
      .then((data) => {
        setpostcomment("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get("users/" + window.localStorage.getItem("id"), {
        // headers: {
        //   Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        // },
      })
      .then((response) => {
        setUser(response);
        console.log("usertest", response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("djfajksfd", discussion);
  return (
    <div className="case-discussion">
      <div className="page-title">
        <h1>{discussion.case_al}</h1>
      </div>
      <div className="row w-100 cases_d">
        <div className="new-discussion col-md-12">
          <div className="chat_info">
            <p>
              <b>Agency: </b>
              {discussion?.gov_al}
            </p>
            <p>
              <b>Municipality:</b> {discussion?.municipality_al}
            </p>
            <p>
              <b>Contact:</b> {discussion?.phone}
            </p>
            <p>
              <b>Email:</b> {discussion?.email}
            </p>
            <div className="foto">
              <b>Img/Video:</b>

              {/* <img
                src={`${process.env.REACT_APP_UPLOADS + discussion?.pic_url}`}
                width="50px"
                height="50px"
                download
              /> */}
              <a
                href={`${process.env.REACT_APP_UPLOADS + discussion.pic_url}`}
                target="_blank"
                download
              >
                Open IMAGE{" "}
              </a>
            </div>
          </div>

          <div className="messages">
            {comments?.map((el) => {
              return (
                <div className="discussions" key={el.id}>
                  {el.uuid == "1" ? (
                    <div className="thread">
                      <div className="row case" id="chatList">
                        {el.content_al}
                      </div>
                    </div>
                  ) : (
                    <div className="reply-comment">
                      <div className="row">
                        <div className="icon">
                          {/* <i className="fas fa-comment"></i> */}
                        </div>
                        <div className="content">{el.content_al}</div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="post_message col-md-12">
          <form onSubmit={handleSubmit} className="message-form">
            <input
              type="text"
              onChange={content_al}
              className="message-input"
              placeholder="Send a message..."
              value={postcomment}
            />

            <button type="submit">
              <SendOutlined className="send-icon" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Discussion;
