import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import "./HeaderProfile.scss";

const HeaderProfile = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    axios
      .get("users/" + window.localStorage.getItem("id"))
      .then((response) => {
        setUser(response?.data);
        console.log("usertest", response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log("sdsdsd", window.localStorage.getItem("id"));
  console.log("SSSs", user);

  return (
    <div className="header-profile">
      <div className="page-title">
        <span>
          {/* <h1> Dashboard</h1> */}
          <p>{user?.full_name}</p>
          <p className="description">{user?.email}</p>
        </span>
        {/* <div className="profile">
          <Link to="/profile">
            <img src={`${process.env.REACT_APP_UPLOADS + user?.pic_url}`} />
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default HeaderProfile;
