import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader/Loader";
import "./events.scss";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const AddEvents = (props) => {
  const [eventdata, addeventdata] = useState({});
  const [userroles, adduserroles] = useState([]);
  const [languages, addlanguages] = useState("al");
  const [isloading, setisloading] = useState(false);
  const [editordata, seteditordata] = useState("");
  const [content, setContent] = useState("");
  const [contentsr, setContentsr] = useState("");
  const [contenten, setContenten] = useState("");
  const [image, setImage] = useState([]);

  useEffect(() => {
    axios
      .get("users/all")
      .then((data) => {
        adduserroles(data?.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const name_al = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.name_al = e.target.value;
    addeventdata(ud);
  };
  const name_sr = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.name_sr = e.target.value;
    addeventdata(ud);
  };
  const name_en = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.name_en = e.target.value;
    addeventdata(ud);
  };

  const desc_al = (value) => {
    setContent(value);
  };
  const desc_sr = (value) => {
    setContentsr(value);
  };
  const desc_en = (value) => {
    setContenten(value);
  };

  // const event_url = (e) => {
  //   const ud = Object.assign({}, eventdata);
  //   ud.event_url = e.target.value;
  //   addeventdata(ud);
  // };

  const from_date = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.from_date = e.target.value;
    addeventdata(ud);
  };

  const to_date = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.to_date = e.target.value;
    addeventdata(ud);
  };

  // const createdAt = (e) => {
  //   const ud = Object.assign({}, eventdata);
  //   ud.created_at = e.target.value;
  //   addeventdata(ud);
  // };

  const address = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.address = e.target.value;
    addeventdata(ud);
  };

  const createdBy = (e) => {
    const ud = Object.assign({}, eventdata);
    ud.created_by = e.target.value;
    addeventdata(ud);
  };

  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      addlanguages("al");
    }
    if (lang === "sr") {
      addlanguages("sr");
    }
    if (lang === "en") {
      addlanguages("en");
    }
  };

  const imageDataChange = (e) => {
    let img = e.target.files[0];
    setImage(img);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", image);
    // formData.append("image", image.event_url);

    eventdata.desc_al = content;
    eventdata.desc_en = contenten;
    eventdata.desc_sr = contentsr;

    let id;

    await axios
      .post("events", eventdata)
      .then((data) => {
        console.log(data);
        id = data.data.id;
        // toast.success("Events has been added successfully!");
        // window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });

    await axios
      .post(`events/image/${id}`, formData)
      .then((data) => {
        console.log(data);
        toast.success("Event has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  // console.log("editordata", editordata);

  return (
    <div className="events p-5 text-left">
      <div className="page-title">
        <h1>Add Events</h1>
      </div>

      {!isloading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <input type="file" onChange={imageDataChange} />

          <label>From date:</label>
          <input
            type="date"
            onChange={from_date}
            required
            title="This field is required"
            placeholder="From Date"
          />

          <label>To date:</label>
          <input
            type="date"
            onChange={to_date}
            required
            title="This field is required"
            placeholder="To Date"
          />

          {/* <input
            type="date"
            onChange={createdAt}
            required
            // defaultValue={eventdata?.createdAt?.slice(0, 10)}
            // disabled
          /> */}

          <input
            type="text"
            onChange={address}
            required
            title="This field is required"
            placeholder="Address"
          />

          <select required title="This field is required" onChange={createdBy}>
            <option value="" disabled selected>
              Created By
            </option>

            {userroles?.map((el, index) => {
              return (
                <option value={el?.id} key={index}>
                  {el?.full_name}
                </option>
              );
            })}
          </select>

          <div className="row ml-2 p-1 mb-5">
            <a
              className={languages == "al" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("al")}
            >
              Albanian
            </a>
            <a
              className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("sr")}
            >
              Serbian
            </a>
            <a
              className={languages == "en" ? "lang-btn-active" : "lang-btn"}
              onClick={() => language("en")}
            >
              English
            </a>
          </div>

          {languages == "al" && (
            <>
              <input
                placeholder="Titulli"
                onChange={name_al}
                defaultValue={eventdata.name_al}
              />
              <ReactQuill
                theme="snow"
                value={content}
                placeholder={"Write something awesome..."}
                onChange={desc_al}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          {languages == "sr" && (
            <>
              <input
                placeholder="Naslov"
                onChange={name_sr}
                defaultValue={eventdata.name_sr}
              />
              <ReactQuill
                theme="snow"
                value={contentsr}
                placeholder={"Write something awesome..."}
                onChange={desc_sr}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          {languages == "en" && (
            <>
              <input
                placeholder="Title"
                onChange={name_en}
                defaultValue={eventdata.name_en}
              />
              <ReactQuill
                theme="snow"
                value={contenten}
                placeholder={"Write something awesome..."}
                onChange={desc_en}
                formats={formats}
                modules={modules}
              />
            </>
          )}

          <button type="submit">Add Events</button>
        </form>
      )}
    </div>
  );
};

export default AddEvents;
