import React, { useState, useEffect } from "react";
import Loader from "../loader/Loader";
import axios from "../../axios";
import { toast } from "react-toastify";
import "./profile.scss";
import Modal from "react-modal";

const customStyles = {
  content: {
    width: "40%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// toast.configure();

const Profile = (props) => {
  const [user, setUser] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [oldpassword, setoldpassword] = useState("");
  const [password, setPassword] = useState("");
  const [repeatpassword, setrepeatPassword] = useState("");
  const [image, setImage] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [enable, setEnable] = useState(true);
  const [error, setError] = useState("");

  Modal.setAppElement("#root");

  const handleEnable = () => {
    setEnable(!enable);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const getCurrentUsers = () => {
    axios
      .get("users/" + window.localStorage.getItem("id"))
      .then((response) => {
        setUser(response?.data);
        console.log("usertest", response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCurrentUsers();
  }, []);

  const full_name = (e) => {
    const ud = Object.assign({}, user);
    ud.full_name = e.target.value;
    setUser(ud);
  };
  const email = (e) => {
    const ud = Object.assign({}, user);
    ud.email = e.target.value;
    setUser(ud);
  };
  const pass = (e) => {
    const ud = Object.assign({}, user);
    ud.pass = e.target.value;
    setUser(ud);
  };
  const role = (e) => {
    const ud = Object.assign({}, user);
    ud.role = e.target.value;
    setUser(ud);
  };
  const active = (e) => {
    const ud = Object.assign({}, user);
    ud.active = e.target.value;
    setUser(ud);
  };

  const handleoldpassword = (e) => {
    setoldpassword(e.target.value);
  };
  const handlenewpassword = (e) => {
    setPassword(e.target.value);
  };
  const handlerepeatpassword = (e) => {
    setrepeatPassword(e.target.value);
  };

  // const imageDataChange = (e) => {
  //   const ud = Object.assign({}, image);
  //   ud.pic_url = e.target.files[0];
  //   setImage(ud);
  // };

  // const saveImage = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("image", image.pic_url);
  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   };

  //   axios
  //     .post(
  //       "users/image/" + window.localStorage.getItem("id"),
  //       formData,
  //       config
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //       toast.success("Image has been changed successfully!");
  //       getCurrentUsers();
  //     })
  //     .catch((err) => {
  //       toast.error("Something went wrong!");
  //       console.log(err);
  //     });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("users", user)
      .then((data) => {
        toast.success(`User ${user.full_name} has been edited successfully!`);
        console.log(data);
        // window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setError();

    let data = {
      oldPass: oldpassword,
      pass: password,
    };

    let error = 0;

    if (password != repeatpassword) {
      error = 1;
      setError("passwordi juaj i ri nuk perputhet me new password");
    } else {
      setError("");
    }

    if (error == 0) {
      axios
        .put(
          "users/editPassword/" + window.localStorage.getItem("id"),
          data
          // {
          // headers: {
          //   Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          // },
          // }
        )
        .then((res) => {
          console.log(res);
          if (res.data === "Please enter correct old password") {
            toast.error(`${res.data}`, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (res.data["updated"] === true) {
            toast.success("Your password has been changed ", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            window.location = "/profile";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="profile p-5 text-left">
      <div className="page-title ">
        <h1>User Profile</h1>
      </div>
      {!isloading ? (
        <Loader />
      ) : (
        <div className="profile-content">
          <div className="user_data ">{/* <p>{user?.full_name}</p> */}</div>
          <div className="row">
            <div className="col-md-12 user__">
              {/* <div className="profile-photo"> */}
              {/* <img src={`${process.env.REACT_APP_UPLOADS + user?.pic_url}`} /> */}
              {/* <div className="row p-3"> */}
              {/* <img
                  src={`${process.env.REACT_APP_UPLOADS + user.pic_url}`}
                  alt="Country Image"
                  style={{
                    border: "1px solid blue",
                    width: "250px",
                    height: "250px",
                    borderRadius: "50%",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    objectFit: "cover",
                  }}
                /> */}
              {/* </div> */}
              {/* </div> */}
              <div className="profile-form">
                <form onSubmit={handleSubmit}>
                  {/* <label for="oldpassword" className="form-label">
                    Change Profile Picture:
                  </label>
                  <input type="file" onChange={imageDataChange} />
                  {!!image && <button onClick={saveImage}>Save Image</button>} */}

                  {/* <label for="oldpassword" className="form-label">
                        First Name:
                        </label> */}
                  <label className="text-left">Update personal info:</label>
                  <input
                    type="text"
                    placeholder="full name"
                    onChange={full_name}
                    defaultValue={user.full_name}
                    disabled={enable ? true : false}
                  ></input>
                  {/* <label for="oldpassword" className="form-label">
                        Last Name:
                        </label> */}
                  <input
                    type="email"
                    placeholder="email"
                    onChange={email}
                    defaultValue={user.email}
                    disabled={enable ? true : false}
                  ></input>
                  {/* <input
                    type="password"
                    placeholder="your password"
                    onChange={pass}
                    defaultValue={user.pass}
                  ></input> */}

                  <select
                    placeholder="date active"
                    onChange={active}
                    defaultValue={user.active}
                    disabled={enable ? true : false}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Passive</option>
                  </select>
                  {/* 
                  <select onChange={role} value={user.role}>
                    <option value={1}>Admin</option>
                    <option value={2}>Client</option>
                  </select> */}
                  <button
                    type={!enable ? "reset" : "submit"}
                    onClick={handleEnable}
                  >
                    {enable ? "Edit Data" : "Save Changes"}
                  </button>
                </form>
              </div>

              {/* CHANGE PASSWORD */}
              {
                <div className="change_pass">
                  <button onClick={openModal}>Change Password</button>

                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <p
                      onClick={closeModal}
                      style={{
                        textAlign: "right",
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    >
                      x
                    </p>

                    <form onSubmit={handleChange}>
                      <label for="oldpassword" className="form-label">
                        Old password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="oldpassword"
                        onChange={handleoldpassword}
                        aria-describedby="emailHelp"
                      />
                      <label for="newpassword" className="form-label">
                        New password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="newpassword"
                        onChange={handlerepeatpassword}
                        aria-describedby="emailHelp"
                      />
                      <label for=" Repeatpassword" className="form-label">
                        Repeatpassword
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="Repeatpassword"
                        onChange={handlenewpassword}
                        aria-describedby="emailHelp"
                      />
                      <p className="text-danger">{error}</p>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "10px" }}
                      >
                        Save changes
                      </button>
                    </form>
                  </Modal>
                </div>
              }
            </div>

            {/* <div className="col-md-8"></div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
