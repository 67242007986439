import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const DeletePartners = (props) => {
  const [partnersdata, deletepartnersdata] = useState({});
  const [languages, editlanguages] = useState("al");

  useEffect(() => {
    axios
      .get("partners/id?id=" + props.match.params.id)
      .then((data) => {
        deletepartnersdata(data?.data);
        console.log("userdataaa", data?.data);
        // setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("partners/?id=" + partnersdata.id)
      .then((data) => {
        console.log(data?.data);
        window.history.back();
      })
      .catch((err) => console.log(err));
  };
  const language = (lang) => {
    // .preventDefault();
    if (lang === "al") {
      editlanguages("al");
    }
    if (lang === "sr") {
      editlanguages("sr");
    }
    if (lang === "en") {
      editlanguages("en");
    }
  };

  return (
    <div className="partners p-5 text-left">
      <div className="page-title">
        <h1>Delete Partners</h1>
      </div>
      <div className="row mb-3 text">
        By clicking delete button you agree to delete partner with the following
        data:
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row ml-2 p-1 mb-5">
          <a
            className={languages == "al" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("al")}
          >
            Albanian
          </a>
          <a
            className={languages == "sr" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("sr")}
          >
            Serbian
          </a>
          <a
            className={languages == "en" ? "lang-btn-active" : "lang-btn"}
            onClick={() => language("en")}
          >
            English
          </a>
        </div>

        {languages == "al" && (
          <>
            <input defaultValue={partnersdata.name_al} disabled />
            <textarea defaultValue={partnersdata.desc_al} disabled />
          </>
        )}
        {languages == "sr" && (
          <>
            <input defaultValue={partnersdata.name_sr} disabled />
            <textarea defaultValue={partnersdata.desc_sr} disabled />
          </>
        )}
        {languages == "en" && (
          <>
            <input defaultValue={partnersdata.name_en} disabled />
            <textarea defaultValue={partnersdata.desc_en} disabled />
          </>
        )}

        <button type="submit">Delete Partner</button>
      </form>
    </div>
  );
};

export default DeletePartners;
