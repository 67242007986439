import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import "./admin_settings.scss";

const AddAdminSettings = (props) => {
  const [setting, addsetting] = useState({});
  // const [userroles, adduserroles] = useState({});
  // const [languages, addlanguages] = useState({});
  // const [isloading, setisloading] = useState(false);

  const option = (e) => {
    const ud = Object.assign({}, setting);
    ud.option = e.target.value;
    addsetting(ud);
  };
  const type = (e) => {
    const ud = Object.assign({}, setting);
    ud.type = e.target.value;
    addsetting(ud);
  };
  const value = (e) => {
    const ud = Object.assign({}, setting);
    ud.value = e.target.value;
    addsetting(ud);
  };
  const content = (e) => {
    const ud = Object.assign({}, setting);
    ud.content = e.target.value;
    addsetting(ud);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("admin-settings", setting)
      .then((data) => {
        console.log(data);
        toast.success("Setting has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <div className="admin_settings p-5 text-left">
      <div className="page-title">
        <h1>Add Setting</h1>
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Option"
          onChange={option}
        ></input>
        <input
          type="text"
          placeholder="Type"
          onChange={type}
          required
          title="This field is required"
        ></input>

        <input
          type="text"
          required
          title="This field is required"
          placeholder="Value"
          onChange={value}
        ></input>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Content"
          onChange={content}
        ></input>

        <button type="submit">Add Setting</button>
      </form>
      {/* } */}
    </div>
  );
};
export default AddAdminSettings;
