import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "../../axios";
import "./case_discussion.scss";

const CaseDiscussion = (props) => {
  const [data, setdata] = useState([]);
  const [filtered, setFiltered] = useState([]);

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 6;
  const productsPerList = 50;
  const pagesVisited = pageNumber * productsPerPage;
  let displaynewcases = [];
  const pageCount = Math.ceil(filtered.length / productsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    axios
      .get("report-cases/all")
      .then((res) => {
        setdata(res?.data);
        setFiltered(res?.data);
        console.log(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("single case", data);

  return (
    <div className="case-discussion">
      <div className="page-title">
        <h1> Case Discussion</h1>
      </div>
      <div className="row w-100 mt-4 cases">
        {
          (displaynewcases = filtered
            ?.slice(pagesVisited, pagesVisited + productsPerPage)
            ?.map((el) => {
              return (
                <div className="new-case" key={el.id}>
                  <div className="row case">
                    <b>Case:</b> {el.case_al}
                  </div>

                  <div className="row type">
                    <b>Report Type:</b> {el.cat_en}
                  </div>

                  <div className="row municipality">
                    <b>Municipality:</b> {el.municipality_al}
                  </div>

                  <div className="row agency">
                    <b>Agency:</b> {el.gov_al}
                  </div>

                  <div className="row contact">
                    <b>Contact:</b> {el.phone}
                  </div>

                  <div className="row contact">
                    <b>Email:</b> {el.email}
                  </div>

                  <div className="row actions ">
                    <Link to={"/report-cases/" + el.id}>
                      {" "}
                      <button className="translate">Discussion</button>
                    </Link>
                  </div>
                </div>
              );
            }))
        }
      </div>
      <div className="row mt-5 ">
        <div className="col-12">
          {data?.length >= productsPerPage ? (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseDiscussion;
