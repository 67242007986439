import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";

const AddUser = (props) => {
  const [userdata, adduserdata] = useState({});
  const [userroles, adduserroles] = useState({});
  const [languages, addlanguages] = useState({});
  const [isloading, setisloading] = useState(false);
  const [communitydata, setCommunity] = useState([]);
  const [image, setImage] = useState(null);

  useEffect(() => {
    axios
      .get("users/all")
      .then((data) => adduserroles(data.data))
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
    axios
      .get("community/all")
      .then((data) => setCommunity(data?.data))
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, []);

  const full_name = (e) => {
    const ud = Object.assign({}, userdata);
    ud.full_name = e.target.value;
    adduserdata(ud);
  };
  const email = (e) => {
    const ud = Object.assign({}, userdata);
    ud.email = e.target.value;
    adduserdata(ud);
  };
  const pass = (e) => {
    const ud = Object.assign({}, userdata);
    ud.pass = e.target.value;
    adduserdata(ud);
  };
  const username = (e) => {
    const ud = Object.assign({}, userdata);
    ud.username = e.target.value;
    adduserdata(ud);
  };
  const active = (e) => {
    const ud = Object.assign({}, userdata);
    ud.active = e.target.value;
    adduserdata(ud);
  };

  const role = (e) => {
    const ud = Object.assign({}, userdata);
    ud.role = e.target.value;
    adduserdata(ud);
  };
  // const pic_url = (e) => {
  //   console.log(e.target.files[0]);
  //   let img = e.target.files[0];
  //   setImage(img);
  //   console.log("fajllimrena", image);
  // };
  const gender = (e) => {
    const ud = Object.assign({}, userdata);
    ud.gender = e.target.value;
    adduserdata(ud);
  };
  const education = (e) => {
    const ud = Object.assign({}, userdata);
    ud.education = e.target.value;
    adduserdata(ud);
  };
  const employment_status = (e) => {
    const ud = Object.assign({}, userdata);
    ud.employment_status = e.target.value;
    adduserdata(ud);
  };
  const age = (e) => {
    const ud = Object.assign({}, userdata);
    ud.age = e.target.value;
    adduserdata(ud);
  };
  const community = (e) => {
    const ud = Object.assign({}, userdata);
    ud.community = e.target.value;
    adduserdata(ud);
  };
  const address = (e) => {
    const ud = Object.assign({}, userdata);
    ud.address = e.target.value;
    adduserdata(ud);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const formData = new FormData();
    // formData.append("image", image);

    let id;
    console.log("userdata", userdata);
    await axios
      .post("users", userdata)
      .then((data) => {
        id = data?.data?.id;
        toast.success("User has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  console.log(languages);

  return (
    <div className="users p-5 text-left">
      <div className="page-title">
        <h1>Add User</h1>
      </div>
      {/* {!isloading ? '' : */}
      <form onSubmit={handleSubmit}>
        {/* <input type="file" onChange={pic_url} /> */}
        {/* <input
          type="text"
          placeholder="full name"
          onChange={full_name}
          required
          title="This field is required"
        ></input> */}
        <input
          type="email"
          title="This field is required"
          placeholder="email"
          onChange={email}
        ></input>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="username"
          onChange={username}
        ></input>
        <input
          type="password"
          required
          title="This field is required"
          placeholder="your password"
          onChange={pass}
        ></input>

        {/* <select required title="This field is required" onChange={gender}>
          <option value="">Select Gender</option>
          <option value={"Male"}>Male</option>
          <option value={"Female"}>Female</option>
        </select> */}

        {/* <input
          type="number"
          required
          title="This field is required"
          placeholder="your age"
          onChange={age}
        ></input> */}

        {/* <input
          type="text"
          required
          title="This field is required"
          placeholder="your address"
          onChange={address}
        ></input> */}
        {/* <select required title="This field is required" onChange={education}>
          <option value="">Select Education</option>
          <option value={"Primary school"}>Primary school</option>
          <option value={"Secondary school"}>Secondary school</option>
          <option value={"Universityl"}>University</option>
          <option value={"Other"}>Other…</option>
        </select> */}

        {/* <select required title="This field is required" onChange={community}>
          <option value="" disabled selected>
            Community
          </option> */}
        {/* <option value={user?.id}>{user?.full_name}</option> */}
        {/* {communitydata?.map((el, index) => {
            return (
              <option value={el.id} key={index}>
                {el?.name_en}
              </option>
            );
          })}
        </select> */}
        {/* <select
          required
          title="This field is required"
          onChange={employment_status}
        >
          <option value="">Employment Status</option>
          <option value={"Employed"}>Employed</option>
          <option value={"Unemployed"}>Unemployed</option>
        </select> */}
        <select
          type="date"
          required
          title="This field is required"
          placeholder="active "
          onChange={active}
        >
          <option value={null} defaultChecked={true}>
            {" "}
            Select Status{" "}
          </option>
          <option value={true}>Active</option>
          <option value={false}>Passive</option>
        </select>

        <select required title="This field is required" onChange={role}>
          <option value="">Select Role</option>
          <option value={1}>Admin</option>
          <option value={2}>Client</option>
        </select>
        <button type="submit">Add User</button>
      </form>
      {/* } */}
    </div>
  );
};
export default AddUser;
