import React, { useState, useEffect } from "react";
import "./mobile.scss";
import { SidebarData } from "./test";
import { useRef } from "react";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const MobileHeader = () => {
  const dropdownRef = useRef(null);
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <div className="navbar__">
        <div className="navbar">
          <Link to="#" className="menu-bars menu_icon">
            <FaBars onClick={showSidebar} />
          </Link>
        </div>

        <nav
          ref={dropdownRef}
          className={sidebar ? "nav-menu" : "nav-menu active"}
        >
          <ul className="nav-menu-items">
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiOutlineClose onClick={showSidebar} />
              </Link>
            </li>
            <div className="list_items">
              {SidebarData.map((item, index) => {
                console.log(item);
                return (
                  <>
                    <li key={index} className={item.className}>
                      <HashLink
                        to={item?.path}
                        onClick={item.subNav && showSubnav}
                      >
                        {item?.title}
                      </HashLink>
                      {/* <a href={item?.path}>
                      <span>{item.title}</span>
                    </a> */}
                      {item.subNav && subnav
                        ? item.iconOpened
                        : item.subNav
                        ? item.iconClosed
                        : null}
                    </li>
                    <ul>
                      {subnav &&
                        item?.subNav?.map((item, index) => {
                          return (
                            <li>
                              <Link to={item.path} key={index}>
                                {item.title}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </>
                );
              })}
            </div>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default MobileHeader;
